import React, { useState } from "react"

export const colorsContext = React.createContext()

const Provider = props => {
  const [savedColors, setSavedColors] = useState([])
  const [isFormDrawerOpen, setIsFormDrawerOpen] = useState(false)
  return (
    <colorsContext.Provider
      value={{
        savedColors,
        addColor: color =>
          setSavedColors(savedColors => [...savedColors, color]),
        isFormDrawerOpen,
        setIsFormDrawerOpen: isOpen => setIsFormDrawerOpen(isOpen),
      }}
    >
      {props.children}
    </colorsContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
